import React from "react"

function WorkExperience() {
  return (
    <div className="yui-gf">
      <div className="yui-u first">
        <h2>Experience</h2>
      </div>

      <div className="yui-u">
      <div className="job">
          <h2>Aptiv | Senior Frontend Developer</h2>
          <h4>Jan 2021 - Present</h4>
          <p>
            Senior front-end developer delivering UI for Aptiv's connected services platform. Proposed and architected shared component library to modernize suite of existing products.
          </p>
          <ul>
            <li>Hired to help at-risk migration project. Proposed and implemented logging integration with DataDog. Immediately useful in identifying problems, and served as a template for other projects to add observability to their products.</li>
            <li>Resolved blocking bug due to computationally expensive data-viz. App crashes dropped from ~1400 a week to &lt; 10 a month. Decrease the time required to render data by ~65% for templates with 8 or more graphs.</li>
          </ul>
          <ul>
            <li>Asked to start front-end development on new dashboard product. Architected solution as a monorepo component library, which allowed team to break down project into smaller internal stakeholder releases (Storybook, diagnostic tools, internal apps), before public launch</li>
            <li>Initiated effort to track/increase test coverage using Jest framework</li>
            <li>Github Actions powered CI/CD pipeline allows us to deliver UI through multiple channels: Standalone web apps + shared component libraries</li>
            <li>Monorepo has allowed us to pursue incremental UX improvement for a legacy Java-based monolith web app via a Micro-Frontend strategy (embedded web components delivered via a CDN).</li>
            <li>Published our first cross-team shared React component to our private node package repository.</li>
            <li>Championed use of AWS Serverless to deliver full-stack solutions quickly and bypass Ops bottlenecks. Our Cloudformation powered infrastructure hosts Storybook, our production CDN and several microfrontend applications.</li>
          </ul>
        </div>
        <div className="job">
          <h2>The Trade Desk | Senior Software Engineer</h2>
          <h4>Feb 2020 – Aug 2020</h4>
          <p>
            Senior front-end developer on the Core Experiences team, which is
            responsible for the Trade Desk's primary customer facing campaign
            management interface. Worked with small team to release a new
            dashboard home page experience over 3 months.
          </p>
          <ul>
            <li>
              Helped define requirements with UX design team and product
              manager. Self-organized JIRA tasks with other developers to
              distribute workload.
            </li>
            <li>
              Implemented React data visualization component for dashboard. To
              meet dev team technical goals of avoiding dependency bloat within
              application, SVG logic was written from scratch. Also designed
              component for general use to meet UX goal of leveraging
              visualization elsewhere in application. A month later, separate
              project managed to reuse component with no upstream changes
              needed.
            </li>
            <li className="no-print">
              Independently architected and implemented a profile image upload
              feature from design concept to release. Used web canvas to crop,
              resize and compress image. Leveraged years of S3 experience
              towards creating a pre-signed url flow that secured upload against
              abuse (enforced mime types, size, and path)
            </li>
          </ul>
          <p>Left company after 7 months during move abroad.</p>
        </div>

        <div className="job">
          <h2>Amazon | Front-End Engineer</h2>
          <h4>July 2016 – Jan 2019</h4>
          <p className="no-print">
            Front-End Engineer on a product team developing Amazon's new
            customer service chat platform, MessageUs.
          </p>
          <ul>
            <li>
              Joined the project during its prototype phase in July 2016, and
              helped roll out a MVP to prove the viability of customer service
              automation in time for the holiday season. In order to meet
              ambitious deadline, I worked with a remote team to leverage an
              existing deprecated chat interface, and extended its
              functionality.
            </li>
            <li>
              After initial promising results, the MVP feature set was expanded
              and the product was incrementally released. During this phase, I
              was responsible for designing and implementing front-end features,
              helping onboard a new front-end engineer, and for monitoring
              releases. As of July 2018, MessageUs is available to all customers
              in the US and India, and has handled over 14 million customer
              service contacts.
            </li>
            <li>
              After initial launch, I proposed splitting the existing chat
              codebase to better streamline development by separating
              responsibilities between remote and Seattle-based team.
              Successfully architected a webpack-based CI pipeline to build from
              multiple repos, and removed collaboration bottleneck. Increased
              our code throughput by 3x, and reduced average code review
              turnaround time from 1.5 weeks to 3 days.
            </li>
            <li>
              By separating the frontend codebase, our team successfully
              migrated from jQuery to an application built on React.js, Redux
              and Redux Saga.
            </li>
            <li>
              I developed a test suite tooling based on Jest and Enzyme, and led
              the effort to ensure that our migration followed a test-driven
              policy. The result is that we migrated from a codebase that was
              entirely manually tested, to one that had 87% test coverage (both
              from unit and functional tests)
            </li>
            <li className="no-print">
              In 2017, the UX team approached the product team with a request to
              conduct a high-fidelity user study. The estimated effort was about
              a month's work spread across several different functional teams.
              However, I proposed using the soon-to-be-released react.js
              codebase instead. I wrote a simple AWS S3 deployment script and
              created an internal app for the UX team in less than a week.
            </li>
            <li>
              Independently identified a gap in our knowledge of user activity,
              and pursued architecting and developing an ElasticSearch based
              user-analytics platform for UI generated metrics. Once system was
              fully rolled-out, the platform ingested over 10gb of user
              analytics a day, and provided our team valuable insights into
              previously hidden user behavior.
            </li>
            <li>
              The ElasticSearch based analytics platform ingested data via a
              Kinesis and a Lambda transformation function. The deployment
              script was based on the Serverless framework, and made heavy use
              of Cloudformation, so that the entire stack could be easily
              created in a single deploy.
            </li>
            <li className="no-print">
              In addition to team responsibilities, I also became an official
              Security Certifier for other projects within the Amazon. This role
              entailed reviewing/testing project architectures for data handling
              compliance, and access control. As many teams within Amazon are
              currently migrating to AWS, I was often tasked with helping
              consult on AWS security best practices.
            </li>
          </ul>
        </div>

        <div className="job">
          <h2>Avalara | Front-End Developer</h2>
          <h4>July 2013 – June 2016</h4>
          <p className="no-print">
            Avalara helps thousands of companies manage sales tax compliance. I
            was part of a small UX team building the next generation portal for
            Avalara's suite of products.
          </p>
          <ul>
            <li>
              Top Github contributor over most of the 2 year project history.
              Helped drive our team's development pace to meet aggressive
              milestones and successfully deliver our product.
            </li>

            <li>
              Developed some of our app's key UI features: Bulk data import
              tools that allowed customers to direct upload data from
              spreadsheets, and integration with ElasticSearch to provide
              typeahead search.
            </li>

            <li className="no-print">
              Took initiative to architect our front end integration testing,
              which allowed our team to switch to a behavior test driven
              methodology, adopt a CI to verify pull requests, and greatly
              reduce regression bugs.
            </li>

            <li className="no-print">
              Maintained an automated full-stack smoke test suite using
              Selenium, helping QA team verify that live environments were free
              of configuration or integration issues.
            </li>

            <li className="no-print">
              Was driving proponent to use SASS as CSS tooling; refactored our
              stylesheets towards a reusable cross-application theme, ultimately
              used by several live customer-facing apps.
            </li>

            <li className="no-print">
              Helped write scripting to deploy Express.js on AWS infrastructure,
              using EC2, ElastiCache. Designed solution to help reduce deploy
              times from 15+ minutes to &lt; 2 minutes using S3 and Cloudfront.
            </li>

            <li className="no-print">
              Recommended and integrated JavaScript logging tool, which was
              successfully used to identify and resolve live customer-affecting
              bugs.
            </li>
          </ul>
        </div>

        <div className="job">
          <h2>Makapen Inc | Co-founder</h2>
          <h4>Jan 2014 – July 2016</h4>
          <p className="no-print">
            Makapen was a web agency that helped entrepreneurs with prototypes
            and MVPs.
          </p>
          <ul>
            <li>
              Architected 15+ MVPs and prototypes for e-commerce, productivity,
              and social startups.
            </li>
            <li>
              Directed the company's technology toolset and strategy to deliver
              solutions to clients significantly faster and cheaper than
              traditional agencies by leveraging SaaS products, including
              Firebase, Auth0, AWS, Google Cloud, Heroku, Braintree, Mandrill.
            </li>
            <li>
              Mentored junior developers on a weekly basis to master Ember.js
              and AWS technologies. This helped scale our business to double
              revenue in our second year.
            </li>
          </ul>
        </div>

        <div className="job">
          <h2>Conductiv | Contract Lead Web Developer </h2>
          <h4>Oct 2012 – May 2013</h4>
          <p className="no-print">
            Conductiv is a startup providing mobile POS solutions for small
            businesses and salespeople. Led an offshore team to deliver POS
            prototype that helped win a six-figure sale from Vans shoes.
          </p>
          <ul>
            <li>
              Architected the AngularJS hybrid web app that allowed our web
              developers to author web templates for a digital product catalog,
              and publish the app on the iOS and Android app stores.
            </li>
            <li className="no-print">
              Leveraged IndexedDB and the FileSystem API to support offline
              needs of traveling sales force.
            </li>
          </ul>
        </div>
        <div className="job">
          <h2>The Foundry Agency | Contract Web Developer</h2>
          <h4>Aug 2011 – Sep 2012</h4>
          <p className="no-print">
            Part of a 3-person team that enabled this small web agency to
            deliver on a large technology contract with Chick-fil-a.
          </p>
          <ul>
            <li className="no-print">
              Used Backbone.js to deliver single page calendaring tool for
              restaurant franchise operators.
            </li>
            <li>
              Consulted Chick-fil-a IT department towards their pilot adoption
              of AWS (EC2, RDS, ElastiCache).
            </li>
          </ul>
          <p className="no-print">
            Responsible for maintaining web content and developing new web
            solutions for the Foundry Agency’s clients.
          </p>
          <ul className="no-print">
            <li>Transformed Photoshop design concepts into web content.</li>
            <li>
              Maintained a custom tailored CMS solution to manage fleet of
              brands' sites.
            </li>
          </ul>
        </div>
        <div className="job no-print">
          <h2>ThreeWill | Contract Web Developer</h2>
          <h4>May 2011 – Aug 2011</h4>
          <p className="no-print">
            Developed web application within the Jive social business platform
            to provide integration with SharePoint.
          </p>
          <ul className="no-print">
            <li>
              Developed an in-page widget, which consumed SharePoint list data
              via WCF web services
            </li>
            <li className="no-print">
              Leveraged JQuery, JQuery UI and other 3rd party UI libraries to
              rapidly develop complex CRUD table interface with inline edit.
            </li>
          </ul>
        </div>
        <div className="job print-empty last-printed">
          <h2>Ciena | Java Developer</h2>
          <h4>Oct 2009 – Aug 2010</h4>
          <p className="no-print">
            Maintained and developed new features for Telecom monitoring
            application.
          </p>
          <ul className="no-print">
            <li>
              Primary developer for new drag and drop functionality for
              personalization.
            </li>
            <li>
              Fixed software bugs within bug tracking and resolution pipeline.
              Collaborated with offshore QA team.
            </li>
            <li>
              Championed and actively pursued refactoring of 15 year old code
              base.
            </li>
          </ul>
        </div>
        <div className="job last no-print">
          <h2>Aflac | Intern</h2>
          <h4>May – Aug 2008 &amp; May – Aug 2009</h4>
          <p className="no-print">
            Developed features for the SharePoint platform used by Aflac's
            corporate offices.
          </p>
          <ul className="no-print">
            <li>
              Involved in project of designing and developing an issue tracking
              web app for IT Security using SharePoint platform.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WorkExperience
