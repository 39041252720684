import React from "react"

function Education() {
  return (
    <div className="yui-gf education">
      <div className="yui-u first">
        <h2>Education</h2>
      </div>
      <div className="yui-u">
        <h2>Georgia Institute of Technology - Atlanta, GA</h2>
        <h4>Aug 2004 – May 2009</h4>
        <p className="no-print">
          Awarded Bachelors in Computer Science in 2009 with honors
        </p>
        <ul className="no-print">
          <li>
            PURA research award for work in using of dynamic tainting as a tool
            for detecting security vulnerabilities.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Education
