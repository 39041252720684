import React, { useCallback } from "react"

import "./main.css"
import "./print.css"

const Header = ({ siteTitle }) => {
  const requestPrint = useCallback(() => {
    window.print()
  }, [])
  return (
    <>
      <div id="hd">
        <div className="yui-gc">
          <div className="yui-u first">
            <h1>Stephen Prockow</h1>
            <h2>Senior Full-Stack Developer</h2>
            <div className="no-print">
              <button id="print-link" onClick={requestPrint}>
                Condensed 1 Page Printable PDF
              </button>
            </div>
          </div>
          <div className="yui-u">
            <div className="contact-info no-print">
              <h3>
                <a href="mailto:contact@stephenprockow.com">
                  contact@stephenprockow.com
                </a>
              </h3>
              <br />
              <h3>
                Github:&nbsp;
                <a href="https://github.com/sprockow">sprockow</a>
              </h3>
            </div>
            <div className="contact-info only-print">
              <h3>contact@stephenprockow.com</h3>
              <br />
              <h3>Github: sprockow</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="quote no-print">
        <p>
          Full Stack Developer with 12 years of experience of building and
          launching web applications. Has a large breadth of experience working
          with startups, medium size corporations and large enterprises. As a
          lead frontend developer, launched one of Amazon's first
          customer-facing React.js applications, which ultimately served 500k+
          customers a week. Has designed full stack infrastructure on several
          cloud services, and specializes in AWS (10+ years experience).
        </p>
        <dl>
          <dt>Frontend</dt>
          <dd>
            6+ years React.js experience, 10+ years full-stack web development experience
          </dd>
        </dl>
      </div>
    </>
  )
}

export default Header
