import React from "react"

function Interests() {
  return (
    <div className="yui-gf interests-section last no-print">
      <div className="yui-u first">
        <h2>Interests</h2>
      </div>
      <div className="yui-u no-print">
        <p>
          Seattle React.js Meetup Co-Organizer
          <span>
            {" "}
            &mdash;{" "}
            <a href="http://www.meetup.com/seattle-react-js/">
              http://www.meetup.com/seattle-react-js/
            </a>
          </span>
        </p>
        <p>Coach and Organizer for Startup Weekend</p>
      </div>
      <div className="yui-u only-print">
        <dl>
          <dt>Interests</dt>
          <dd>
            Seattle React.js Meetup Co-Organizer, Coach and Organizer for
            Startup Weekend
          </dd>
        </dl>
      </div>
    </div>
  )
}

export default Interests
