import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import WorkExperience from "../components/WorkExperience"
import Education from "../components/Education"
import Skills from "../components/Skills"
import Interests from "../components/Interests"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Resume" />
      <Header />
      <div id="bd">
        <div id="yui-main">
          <div className="yui-b">
            <WorkExperience />
            <Education />
            <Skills />
            <Interests />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
