import React from "react"

function Skills() {
  return (
    <div className="yui-gf no-print">
      <div className="yui-u first">
        <h2>Technologies</h2>
      </div>
      <div className="yui-u skills-list">
        <dl>
          <dt>Frameworks &amp; platforms</dt>
          <dd>
            4+ years React.js, Node, Typescript, Flow, Ember.js, Express,
            Angular, JQuery, .NET mvc/web-api
          </dd>
          <dt>Cloud</dt>
          <dd>
            8 years AWS experience, 4 years Lambda and Serverless framework,
            Auth0, Firebase
          </dd>
          <dt>Tooling</dt>
          <dd>Git, Webpack, Jest, Selenium</dd>
        </dl>
      </div>
    </div>
  )
}

export default Skills
